import React, {useState} from "react";
import { useGlobal } from "reactn";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import App from "./app";
import * as Styled from "../css/newsStyle";
import getLang from "../components/scripts/getLang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

import { NewsQuery } from "../components/scripts/graphQlQuerys/newsQuery"

const AllNewsPage = () => {
  let [amount, setAmount] = useState(9);
  let [english] = useGlobal("English");

  let ShowMore = (amount) => {
    setAmount(amount + 9);
  }

  let allNews = getLang(english, NewsQuery());
  allNews = allNews.sort((a, b) => {
    return (a.node.nyhetsDatum===null)-(b.node.nyhetsDatum===null) || -(a.node.nyhetsDatum>b.node.nyhetsDatum)||+(a.node.nyhetsDatum<b.node.nyhetsDatum);
});
  let AllNewsFromContentful = allNews.map((item, index) => {
    return (
      <BackgroundImage
        Tag="div"
        className={"allNews"}
        fluid={item.node.huvudBild.fluid}
        backgroundColor={`#040e18`}
        key={index}
      >
        <Link to={`/news/${item.node.slug}`}>
          <div>
            <h3 style={{display:'grid'}} className="hover">{item.node.rubrik} <span style={{fontSize:'15px'}}>{item.node.nyhetsDatum}</span></h3>
          </div>
        </Link>
      </BackgroundImage>
    );
  });
  return (
    <App>
      <SEO description={english ? "All news from Stureplan " : "Alla Nyheter från Stureplan"} title={english ? "All News" : "Alla Nyheter"} />
         <Styled.AllNews>{AllNewsFromContentful.slice(0,amount)}</Styled.AllNews>
          <Styled.ShowMore>
            <FontAwesomeIcon onClick={() => ShowMore(amount)} className="arrowDown" icon={faArrowCircleDown} />
          </Styled.ShowMore>
    </App>
  );
};
export default AllNewsPage;
